import React from "react"
import "./layout.css"
import { Link } from "gatsby"
import useTwilioVideo from "../hooks/use-twilio-video"
import styled from "styled-components"

const HeaderStyles = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Layout = ({ children }) => {
  const {
    state,
    startVideo,
    videoRef,
    screenShareRef,
    leaveRoom,
    shareScreen,
  } = useTwilioVideo()
  return (
    <>
      <HeaderStyles>
        <Link to="/"> Momentum</Link>
        {state.room && (
          <>
            <p> Room: {state.roomName} </p>
            <p onClick={leaveRoom}> Leave </p>
          </>
        )}
      </HeaderStyles>
      <main>{children}</main>
    </>
  )
}

export default Layout
